export const baseUrl = `${window.location.origin}/api/v1`;
export const isPreOrderForm = false;
export const pochtaWidgetKey = process.env.REACT_APP_BASE_IS_POCHTA_WIDGET_KEY || 30636;
export const cloudpaymentsKey =
	process.env.REACT_APP_BASE_IS_CLOUDPAYMENTS_KEY || 'pk_e5b782fa4f1bc12149a154eed9b91';

// айдишники для рассылок от unisender
export const unisenderNewslettersIDs = {
	envelope: 9, // новостная рассылка(конверт)
	agenda: 22, // повестка
	donate: 11, // все соучастники, оформившие успешное пожертвование
	donate_rec_3000: 8, // рекурренты с суммой ежемесячного платежа от 3000
	donate_rec_1: 5, // рекурренты с суммой ежемесячного платежа 1-2999
	donate_1_time_xl: 4, // разовые жертвователи с платежом от 3000
	donate_1_time_s: 3, // разовые жертвователи с платежом 1-2999
	donate_unfinished: 7, // соучастники, которые не завершили оформление пожертвования
	shop: 14
};

export const defaultPage = `${window.location.origin}/subscribe`;

export const newsletters = [
	{
		slug: 'envelope',
		title: '«Конверт»',
		description:
			'Еженедельный (а может, скоро и чаще) рассказ о том, что важного и интересного появилось на наших платформах, новости редакции, материалы на актуальные темы, которые можно будет прочесть только в рассылке, ну или раньше, чем на других ресурсах',

		periodicity: 'Раз в неделю'
	},
	{
		slug: 'shop',
		title: '«Shop было»',
		description:
			'Новая рассылка со скидками для тех, кто покупает наши журналы, газеты, книги, одежду и сувениры в магазине редакции. «Та самая “Новая газета”» в PDF-версии — в подарок!',
		periodicity: 'Раз в неделю'
	},
	{
		slug: 'donate',
		title: '«Соучастнику. От главреда и редакции»',
		description:
			'Если вы захотите стать Соучастником «Новой», чтобы помочь нам выживать фактически в окружении, в вашей почте появится еще одна рассылка — с эксклюзивными письмами от главного редактора и редакции «Новой«, рекомендациями, авторскими колонками, статьями, которые публикуются только здесь. Для соучастников доступна бесплатно и «Та самая “Новая газета”» — PDF-версия издания в таком виде, в котором она бы могла выходить на бумаге, если бы государство так панически нас не боялось',
		periodicity: 'Два раза в неделю'
	},
	{
		slug: 'agenda',
		title: '«Не-Повестка»',
		description:
			'Медиа для тех, кому не все равно. Новости гражданского сопротивления, обзор громких политических процессов, хроника репрессий и последние слова новых политзаключенных. Мы должны сохранять память о Поступках. И не забыть палачей. Не можешь исправить — фиксируй.',
		periodicity: 'Два раза в месяц'
	}
];

export const pageTitles = {
	terms: 'Пользовательское соглашение',
	privacy: 'Политика конфиденциальности'
};
